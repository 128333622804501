<script lang="ts">
const SUNDAY_AS_NUMBER = 0
const SATURDAY_AS_NUMBER = 6
</script>
<script setup lang="ts">
const { country } = useAuth()
const { t } = useI18n()

const currentTime = useNow({
  interval: 60_000,
})

const dayjs = useDayjs()

const timezoneByCountry = computed(() => {
  if (!country.value) {
    return undefined
  }

  const timezones = {
    FI: 'Europe/Helsinki',
    SE: 'Europe/Stockholm',
    GB: 'Europe/London',
    DE: 'Europe/Berlin',
    PL: 'Europe/Warsaw',
  } satisfies Record<App.Internals.Enums.Country, string>

  return timezones[country.value]
})

const activeStartHour = computed(() => country.value === 'SE' ? 10 : 9)
const activeEndHour = computed(() => country.value === 'SE' ? 14 : 15)

const chatOpenTime = computed(() => country.value ? dayjs().tz(timezoneByCountry.value).hour(activeStartHour.value).minute(0).second(0) : undefined)
const chatCloseTime = computed(() => country.value ? dayjs().tz(timezoneByCountry.value).hour(activeEndHour.value).minute(0).second(0) : undefined)

const currentDay = computed(() => country.value ? dayjs(currentTime.value).tz(timezoneByCountry.value).day() : undefined)




const isActive = computed(() =>
  !!(chatOpenTime.value && chatCloseTime.value && currentDay.value)
  && dayjs(currentTime.value).tz(timezoneByCountry.value).isBetween(chatOpenTime.value, chatCloseTime.value, 'minute')
  && ![SUNDAY_AS_NUMBER, SATURDAY_AS_NUMBER].includes(currentDay.value),
)
</script>

<template>
  <slot :is-active="isActive">
    <LawyerChatImage />

    <div class="flex flex-col gap-1">
      <div class="flex items-center gap-2">
        <span class="text-sm font-semibold">{{ t('lawyerChat.title') }}</span>
        <LawyerChatActiveIndicator :is-active="isActive" />
      </div>
      <span class="text-xs">{{ t('lawyerChat.description') }}</span>
    </div>

    <LawyerChatButton :button-props="{ size: 'xs' }">
      {{ t('lawyerChat.cta') }}
    </LawyerChatButton>
  </slot>
</template>
